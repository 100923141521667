import React, { FC, useCallback } from 'react';
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import { FormikProps } from 'formik';
import TextFieldStyled from 'components/formik/TextFieldStyled';
import FieldTitle from '../FieldTitle';
import SelectStyled from 'components/formik/SelectStyled/SelectStyled';
import { DatePicker } from '@mui/x-date-pickers';
import SwitchStyled from 'components/formik/SwitchStyled';
import { fillArrayWithNumbersTill } from '../../helpers/fillArrayWithNumbersTill';
import AddressInput from 'components/AddressInput';
import { Address } from 'models/common/Address';
import { format } from 'date-fns';

const MONTHS_OPTIONS = fillArrayWithNumbersTill(12);

const ProgramForm: FC<Props> = ({ formik, index }) => {
  const theme = useTheme();
  const { office_name, address, program } = formik.values.offices[index];
  const { errors, touched }: { errors: IterableObject; touched: IterableObject } = formik;
  const buildName = useCallback((name: string) => `offices.${index}.${name}`, [index]);

  const onAddressChange = useCallback(
    (nextAddress: Address) => {
      const nextOffices = [...formik.values.offices];
      const nextOffice = { ...nextOffices[index] };

      nextOffice.address = nextAddress;
      nextOffices.splice(index, 1, nextOffice);
      formik.setValues({
        ...formik.values,
        offices: nextOffices,
      });
    },
    [formik, index],
  );

  return (
    <>
      <Typography
        component={'h6'}
        sx={{
          fontSize: 12,
          color: theme.palette.secondary.main,
          mb: 0.5,
        }}
      >
        Num. {index + 1}
      </Typography>
      <Box
        sx={{
          background: '#F5F5F5',
          borderRadius: `${theme.shape.borderRadius}px`,
          p: 3,
          mb: 2,
          '&:last-child': {
            mb: 0,
          },
        }}
      >
        <FieldTitle>PROGRAM CONFIGURATION INFO</FieldTitle>
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={4} md={2}>
            <SelectStyled
              required
              value={program.number_of_month_for_each_subscription}
              name={buildName('program.number_of_month_for_each_subscription')}
              label={'Num. of months'}
              formik={formik}
              options={MONTHS_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <DatePicker
              disablePast
              label={'Starting Date'}
              value={program.program_start_date}
              onChange={value =>
                formik.setFieldValue(buildName('program.program_start_date'), format(value, 'MM/dd/yyyy'))
              }
              renderInput={params => <TextField {...params} variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchStyled
              label={'Include a SmartWatch?'}
              name={buildName('program.watch')}
              checked={program.watch}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchStyled
              label={'Include stress expert?'}
              name={buildName('program.stress_expert')}
              checked={program.stress_expert}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchStyled
              label={'Include Nutritionist?'}
              name={buildName('program.nutrition_expert')}
              checked={program.nutrition_expert}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchStyled
              label={'Include Webinar?'}
              name={buildName('program.webinar')}
              checked={program.webinar}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <FieldTitle>DELIVERY INFO</FieldTitle>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <TextFieldStyled
              required
              value={office_name}
              name={buildName('office_name')}
              formik={formik}
              label="Office Name"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AddressInput
              inputProps={{
                label: 'Address',
                fullWidth: true,
                required: true,
                variant: 'standard',
                onBlur: formik.handleBlur,
                value: address.address1,
                name: buildName('address.address1'),
                error: !!touched.offices?.[index]?.address?.address1 && !!errors.offices?.[index]?.address?.address1,
                // helperText: !!touched.offices?.[index]?.address?.address1
                //   ? errors.offices?.[index]?.address?.address1
                //   : '',
              }}
              onChange={onAddressChange}
              values={address}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SwitchStyled
              label={'Home delivery'}
              name={buildName('program.home_delivery')}
              checked={program.home_delivery}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

interface Props {
  index: number;
  formik: FormikProps<any>;
}

interface IterableObject {
  [key: string]: any;
}

export default ProgramForm;
