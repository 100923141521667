import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { FormikValues, useFormik } from 'formik';
import { useEffect } from 'react';
import { Status } from 'models/company/Company';
import { addCompany } from 'pages/Companies/thunks/addCompany';
import { updateCompany } from 'pages/Companies/thunks/updateCompany';
import { OfficeDTO, RequestCreateCompanyDTO, RequestUpdateCompanyDTO } from 'models/company/CompanyDTO';
import { companyCreateSchema, companyEditSchema } from '../schemas/company';
import { format } from 'date-fns';

const officeInitValues: OfficeDTO = {
  office_name: '',
  address: {
    country: '',
    city: '',
    province: '',
    address1: '',
    address2: '',
    phone: '+',
    zip_code: '',
  },
  program: {
    number_of_month_for_each_subscription: 3,
    program_start_date: format(new Date(), 'MM/dd/yyyy'),
    watch: true,
    webinar: true,
    nutrition_expert: true,
    stress_expert: true,
    home_delivery: false,
    next_order_date: '',
  },
};

const createInitValues: RequestCreateCompanyDTO = {
  name: '',
  number_of_delivery_offices: '',
  programAdminFullName: '',
  programAdminEmail: '',
  programAdminPhone: '+',
  embed_calendar_code: '',
  domain_name: '',
  offices: [],
  status: Status.Active,
  logo_url: '',
};

export const useForm = ({ companyId }: Props) => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(state => state.companies);
  const isCreating = !companyId;
  const initialValues = isCreating
    ? createInitValues
    : (JSON.parse(JSON.stringify(data[companyId])) as RequestUpdateCompanyDTO);

  const formik = useFormik({
    initialValues,
    validationSchema: isCreating ? companyCreateSchema : companyEditSchema,
    onSubmit: async (values: FormikValues) => {
      if (isCreating) {
        await dispatch(addCompany(values as RequestCreateCompanyDTO));
      } else {
        await dispatch(updateCompany({ data: values as RequestUpdateCompanyDTO, companyId }));
      }
    },
  });

  useEffect(() => {
    const offices: any[] = formik.values.offices;
    const programsLength = offices.length;
    const nextLength = Number(formik.values.number_of_delivery_offices);

    if (programsLength === nextLength) {
      return;
    }

    if (programsLength > nextLength) {
      offices.length = nextLength;
    } else if (programsLength < nextLength) {
      const nextAmount = nextLength - programsLength;

      for (let i = 0; i < nextAmount; i++) {
        offices.push({ ...officeInitValues });
        formik.validateForm().then();
      }
    }
  }, [formik]);

  return { formik };
};

interface Props {
  companyId?: string;
}
