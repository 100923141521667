import httpService from 'services/httpService';
import {
  RequestCreateCompanyDTO,
  RequestGetCompaniesDTO,
  RequestUpdateCompanyDTO,
  RequestUploadLogoDTO,
  ResponseGetCompaniesDTO,
  ResponseUploadLogoDTO,
} from 'models/company/CompanyDTO';
import { Response } from 'models/common/Response';
import { Company } from 'models/company/Company';

class CompaniesService {
  private readonly baseUrl = '/companies';

  public getCompanies = async (params: RequestGetCompaniesDTO): Promise<ResponseGetCompaniesDTO> => {
    const res = await httpService.client.get<Response<ResponseGetCompaniesDTO>>(`${this.baseUrl}/search`, { params });

    return res.data.result;
  };

  public getCompany = async (id: string): Promise<Company> => {
    const res = await httpService.client.get<Response<Company>>(`${this.baseUrl}/company/${id}`);

    return res.data.result;
  };

  public addCompany = async (data: RequestCreateCompanyDTO): Promise<Company> => {
    //TODO: DELETE AFTER TESTS
    //
    // const preparedData = JSON.parse(JSON.stringify(data));
    // delete preparedData.programAdminFullName;
    // delete preparedData.programAdminEmail;
    // delete preparedData.programAdminPhone;
    // preparedData.admin_user_id = '6242c82803c21a3cad514395';
    //
    // const res = await httpService.client.post(`${this.baseUrl}`, preparedData);

    const res = await httpService.client.post<Response<Company>>(`${this.baseUrl}`, data);

    return res.data.result;
  };

  public updateCompany = async ({
    companyId,
    data,
  }: {
    companyId: string;
    data: RequestUpdateCompanyDTO;
  }): Promise<Company> => {
    const res = await httpService.client.patch<Response<Company>>(`${this.baseUrl}/company/${companyId}`, data);

    return res.data.result;
  };

  public deleteCompany = async (companyId: string): Promise<Company> => {
    const res = await httpService.client.delete<Response<any>>(`${this.baseUrl}/company/${companyId}`);

    return res.data.result;
  };

  public uploadLogo = async (data: RequestUploadLogoDTO): Promise<ResponseUploadLogoDTO> => {
    const res = await httpService.client.post<Response<ResponseUploadLogoDTO>>(`${this.baseUrl}/uploadLogo`, data);

    return res.data.result;
  };
}

const companiesService = new CompaniesService();

export default companiesService;
