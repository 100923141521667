import * as yup from 'yup';

export const address = yup.object().shape({
  address1: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  address2: yup.string().min(2, 'must be at least 2 characters').max(254, 'cannot be more than 254 characters'),
  city: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  phone: yup.string().min(10, 'must be at least 10 characters').required('this field is required'),
  country: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  province: yup.string().min(2, 'must be at least 2 characters').max(254, 'cannot be more than 254 characters'),
  zip_code: yup
    .string()
    .min(5, 'must be at least 2 characters')
    .max(11, 'cannot be more than 254 characters')
    .required('this field is required'),
});
