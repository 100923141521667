import React, { ChangeEvent } from 'react';
import { Button, CircularProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { uploadCSV } from 'pages/Company/components/Employees/thunks/uploadCSV';
import { useEmployees } from '../../../../hooks/useEmployees';
import { useGetCompany } from '../../../../../../hooks/useGetCompany';

const UploadCsv = () => {
  const fetchEmployees = useEmployees();
  const dispatch = useAppDispatch();
  const company = useGetCompany();
  const { loading } = useAppSelector(state => state.employees);

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const formData = new FormData();

    if (!file) {
      return null;
    }

    formData.append('file', file, file.name);
    await dispatch(uploadCSV({ companyId: company!.id, data: formData }));
    fetchEmployees();
  };

  return (
    <Button variant={'contained'} size={'large'} startIcon={loading ? null : <UploadIcon />} component={'label'}>
      {loading ? <CircularProgress sx={{ color: '#fff' }} size={30} /> : 'Bulk User Creation'}
      <input type={'file'} accept={'.csv'} hidden onChange={onChange} />
    </Button>
  );
};

export default UploadCsv;
