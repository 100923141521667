import React, { FC } from 'react';
import { Box, Grid, InputAdornment, Stack } from '@mui/material';
import UserSearch from '../../../UserSearch';
import TextFieldStyled from 'components/formik/TextFieldStyled';
import HtmlIcon from '@mui/icons-material/Html';
import SwitchStyled from 'components/formik/SwitchStyled';
import { Status } from 'models/company/Company';
import { FormikProps } from 'formik';

const Edit: FC<Props> = ({ formik }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <UserSearch
          onSelect={value => formik.setFieldValue('admin_user_id', value)}
          value={formik.values.admin_user_id}
          label="New Admin Email"
          fullWidth
          variant="standard"
          helperText={'Minimum 5 symbols'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <TextFieldStyled
            name={'embed_calendar_code'}
            label="Embedding Calendly"
            formik={formik}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HtmlIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SwitchStyled
                  label={'Is Company Active?'}
                  name={'status'}
                  checked={formik.values.status === Status.Active}
                  onChange={ev => formik.setFieldValue('status', ev.target.checked ? Status.Active : Status.Inactive)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SwitchStyled
                  label={'Show Dashboard?'}
                  name={'show_dashboard'}
                  checked={formik.values.show_dashboard}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

interface Props {
  formik: FormikProps<any>;
}

export default Edit;
