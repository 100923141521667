import React, { FC } from 'react';
import { FormControl, FormControlLabel, FormLabel, Switch, SwitchProps } from '@mui/material';

const SwitchStyled: FC<SwitchProps & Props> = ({ label, ...rest }) => {
  return (
    <FormControl fullWidth>
      <FormLabel sx={{ fontSize: 12 }}>{label}</FormLabel>
      <FormControlLabel
        control={<Switch color="primary" {...rest} />}
        label={rest.checked ? 'Yes' : 'No'}
        labelPlacement={'end'}
      />
    </FormControl>
  );
};

interface Props {
  label: string;
  name: string;
  checked: boolean;
  onChange: (ev: any) => void;
}

export default SwitchStyled;
