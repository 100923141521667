import { useCallback, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { usePrevious } from 'hooks/usePrevious';
import { useAppSelector } from 'hooks/useRedux';

export const useDialog = ({ formik, onClose }: Props) => {
  const { errors } = useAppSelector(state => state.companies);
  const [warningIsOpen, setWarningIsOpen] = useState(false);

  const _onClose = useCallback(() => {
    if (!formik.dirty) {
      formik.resetForm();
      onClose();
    } else {
      setWarningIsOpen(true);
    }
  }, [formik, onClose]);

  const onWarningSubmit = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [formik, onClose]);

  const onWarningCancel = useCallback(() => {
    setWarningIsOpen(false);
  }, []);

  const prevSubmitting = usePrevious(formik.isSubmitting);

  useEffect(() => {
    if (!formik.isSubmitting && prevSubmitting && !errors?.length) {
      formik.resetForm();
      onClose();
    }
  }, [errors, formik, onClose, prevSubmitting]);

  return { _onClose, warning: { isOpen: warningIsOpen, onSubmit: onWarningSubmit, onCancel: onWarningCancel } };
};

interface Props {
  formik: FormikProps<any>;
  onClose: () => void;
}
