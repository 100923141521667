import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user/User';
import { getEmployees } from './thunks/getEmployees';
import { ResponseGetEmployeesDTO } from 'models/user/UserDTO';
import { updateEmployee } from './thunks/updateEmployee';
import { addEmployee } from './thunks/addEmployee';
import { deleteEmployee } from './thunks/deleteEmployee';
import { uploadCSV } from './thunks/uploadCSV';

export interface EmployeesState {
  data: {
    [key: string]: User;
  };
  loading: boolean;
  errors: string[];
  limit: number;
  total: number;
}

const initialState: EmployeesState = {
  data: {},
  loading: false,
  errors: [],
  limit: 0,
  total: 0,
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEmployees.pending.type, state => {
        state.data = {};
        state.loading = true;
        state.errors = [];
      })
      .addCase(getEmployees.fulfilled.type, (state, action: PayloadAction<ResponseGetEmployeesDTO>) => {
        const data: { [key: string]: User } = {};
        const { users, limit, total } = action.payload;

        users.forEach((value: User) => (data[value.id] = value));
        state.loading = false;
        state.data = data;
        state.limit = limit;
        state.total = total;
      })
      .addCase(uploadCSV.fulfilled.type, state => {
        state.loading = false;
      })
      .addCase(deleteEmployee.fulfilled.type, (state, action: PayloadAction<User>) => {
        const nextData = JSON.parse(JSON.stringify(state.data));

        delete nextData[action.payload.id];
        state.loading = false;
        state.data = nextData;
      })
      .addMatcher(isFulfilled(updateEmployee, addEmployee), (state, action: PayloadAction<User>) => {
        state.loading = false;
        state.data[action.payload.id] = action.payload;
      })
      .addMatcher(isPending(addEmployee, updateEmployee, deleteEmployee, uploadCSV), state => {
        state.loading = true;
        state.errors = [];
      })
      .addMatcher(isRejected(getEmployees, addEmployee, updateEmployee, deleteEmployee, uploadCSV), (state, action) => {
        state.loading = false;
        state.errors = action.payload as string[];
      });
  },
});

export default slice.reducer;
