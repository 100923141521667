import axios, { AxiosInstance } from 'axios';
import localStorageService from './localStorageService';

class HttpService {
  public readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL_API,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.client.interceptors.request.use(config => {
      if (!config.headers) {
        return config;
      }

      config.headers['Authorization'] = `Bearer ${localStorageService.getAccessToken()}`;

      return config;
    });

    this.client.interceptors.response.use(
      res => res,
      async err => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorageService.clearToken();
          window.location.href = '/login';
          return null;
        }
        throw err;
      },
    );
  }
}

const httpService = new HttpService();

export default httpService;
