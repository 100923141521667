import { Option } from 'components/formik/SelectStyled/SelectStyled';

export const fillArrayWithNumbersTill = (finalNumber: number) => {
  const result: Option[] = [];

  for (let i = 1; i <= finalNumber; i++) {
    result.push({
      title: String(i),
      value: i,
    });
  }

  return result;
};
