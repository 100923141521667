import React, { ChangeEvent, FC, memo, useCallback } from 'react';
import { Box, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const _Pagination: FC<Props> = ({ count, limit }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Math.ceil(Number(searchParams.get('offset')) / limit) + 1 || 1;

  const onPageChange = useCallback(
    (event: ChangeEvent<any>, page: number) => {
      searchParams.set('offset', String((page - 1) * limit));
      setSearchParams(searchParams);
    },
    [limit, searchParams, setSearchParams],
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
      <Pagination count={count} color="primary" onChange={onPageChange} page={currentPage} />
    </Box>
  );
};

interface Props {
  count: number;
  limit: number;
}

export default memo(_Pagination);
