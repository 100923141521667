import httpService from 'services/httpService';
import { User } from 'models/user/User';
import {
  RequestGetEmployeesDTO,
  RequestUploadCsvDTO,
  ResponseGetEmployeesDTO,
  ResponseUploadCsvDTO,
} from 'models/user/UserDTO';
import { Response } from 'models/common/Response';

class UserService {
  private readonly baseUrl = '/users';

  public getUser = async (): Promise<User> => {
    const res = await httpService.client.get(this.baseUrl);

    return res.data.result;
  };

  public getUsersByEmail = async (text: string): Promise<{ users: User[]; count: number }> => {
    const res = await httpService.client.post(`${this.baseUrl}/search`, { text });

    return res.data.result;
  };

  public getUsersByCompany = async (params: RequestGetEmployeesDTO): Promise<ResponseGetEmployeesDTO> => {
    const res = await httpService.client.get(`${this.baseUrl}/company/${params.companyId}/users`, { params });

    return res.data.result;
  };

  public addUser = async (user: User): Promise<User> => {
    const res = await httpService.client.post(`${this.baseUrl}/companyUser`, user);

    return res.data.result;
  };

  public updateUser = async (data: User): Promise<User> => {
    const res = await httpService.client.patch(this.baseUrl, data);

    return res.data.result;
  };

  public uploadCSV = async (data: RequestUploadCsvDTO): Promise<ResponseUploadCsvDTO> => {
    const res = await httpService.client.post<Response<ResponseUploadCsvDTO>>(`${this.baseUrl}/import/${data.companyId}`, data.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data.result;
  };

  public deleteUser = async (userId: string): Promise<User> => {
    const res = await httpService.client.delete(`${this.baseUrl}/deleteUser/${userId}`);

    return res.data.result;
  };
}

const userService = new UserService();

export default userService;
