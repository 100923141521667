import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user/User';
import { getUserThunk } from './thunk/getUserThunk';
import localStorageService from 'services/localStorageService';

export interface UserState {
  user: User | null;
  loading: boolean;
}

const initialState: UserState = {
  user: null,
  loading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    clearUser: state => {
      state.user = null;
      localStorageService.clearToken();
      localStorageService.clearRedirection();
      window.location.href = '/';
    },
  },
  extraReducers: {
    [getUserThunk.fulfilled.type]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [getUserThunk.pending.type]: state => {
      state.loading = true;
    },
    [getUserThunk.rejected.type]: state => {
      state.loading = false;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
