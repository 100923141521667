import React, { memo } from 'react';
import TableRow from '@mui/material/TableRow';
import SortByCell from 'components/SortByCell/SortByCell';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Roles } from 'models/user/User';
import { useAppSelector } from 'hooks/useRedux';

const Header = () => {
  const { user } = useAppSelector(state => state.user);
  const isSuperAdmin = user?.role === Roles.SuperAdmin;

  return (
    <TableHead>
      <TableRow>
        <SortByCell sortField={'name'}>Company Name</SortByCell>
        <SortByCell sortField={'domain_name'} align="center">
          Domain’s Name
        </SortByCell>
        <SortByCell sortField={'status'} align="center">
          Status
        </SortByCell>
        <SortByCell sortField={'employee_num'} align="center">
          Num. of Employees
        </SortByCell>
        <TableCell align="center">User’s dashboard</TableCell>
        {isSuperAdmin && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

export default memo(Header);
