import * as React from 'react';
import { forwardRef, ReactElement } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => {
    const { children, ...rest } = props;
    return (
      <Slide direction="up" ref={ref} {...rest} unmountOnExit mountOnEnter>
        {children}
      </Slide>
    );
  },
);

export default Transition;
