import React, { FC } from 'react';
import { Typography } from '@mui/material';

const FieldTitle: FC<{ children: string }> = ({ children }) => {
  return (
    <Typography component={'h6'} sx={{ textTransform: 'uppercase', fontWeight: 700, mb: 1 }}>
      {children}
    </Typography>
  );
};

export default FieldTitle;
