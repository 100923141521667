import { useSnackbar } from 'notistack';
import { ChangeEvent, RefObject, useCallback, useEffect, useState } from 'react';
import companiesService from 'services/companiesService';

const trimBase64 = (source: string): string => {
  return source.split('base64,')[1];
};

const fileReader = new FileReader();

export const useLogoUploader = ({ onChange, src, inputRef }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [preview, setPreview] = useState<string | undefined>(src);
  const [file, setFile] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!file) {
      return;
    }

    fileReader.onload = async () => {
      try {
        setLoading(true);
        const { url } = await companiesService.uploadLogo({
          file: trimBase64(String(fileReader.result)),
          mimetype: file.type,
          filename: file.name,
        });

        onChange(url);
        setPreview(url);
      } catch (err: any) {
        const { message } = err.response?.data;

        if (!!message) {
          enqueueSnackbar(message, { variant: 'error' });
        }
      } finally {
        setLoading(false);
      }
    };

    fileReader.onerror = error => {
      enqueueSnackbar(error, { variant: 'error' });
    };

    fileReader.readAsDataURL(file);
  }, [enqueueSnackbar, file, onChange]);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(file);
  }, []);

  const onRootClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  return { preview, loading, onInputChange, onRootClick };
};

interface Props {
  inputRef: RefObject<HTMLInputElement>;
  onChange: (url: string) => void;
  src?: string;
}
