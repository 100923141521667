import React, { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Company, Status } from 'models/company/Company';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import TableActions from 'components/TableActions';
import CircleIcon from '@mui/icons-material/Circle';
import { useAppSelector } from 'hooks/useRedux';
import { Roles } from 'models/user/User';

const ListItem: FC<Props> = ({ data, actions }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { name } = data;
  const isActive = data.status === Status.Active;
  const { user } = useAppSelector(state => state.user);
  const isSuperAdmin = user?.role === Roles.SuperAdmin;

  return (
    <TableRow sx={{ '&:last-child td': { border: 0 } }}>
      <TableCell>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <CircleIcon sx={{ color: isActive ? '#53CE53' : '#E21F06', fontSize: 14, mr: 1 }} />
          <Link to={`/${data.id}`}>{name}</Link>
        </Box>
      </TableCell>
      <TableCell align="center">{data.domain_name}</TableCell>
      <TableCell align="center">
        <Typography component={'span'} sx={{ color: isActive ? '#53CE53' : theme.palette.secondary.main }}>
          {data.status}
        </Typography>
      </TableCell>
      <TableCell align="center">{data.employee_num}</TableCell>
      <TableCell align="center">
        {data.show_dashboard && (
          <Button variant={'contained'} color={'secondary'} onClick={() => navigate(`/${data.id}/dashboard`)}>
            Dashboard
          </Button>
        )}
      </TableCell>
      {isSuperAdmin && (
        <TableCell align="center">
          <TableActions itemId={data.id} actions={actions} />
        </TableCell>
      )}
    </TableRow>
  );
};

interface Props {
  data: Company;
  actions: { title: string; action: (data: string) => void }[];
}

export default ListItem;
