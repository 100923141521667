import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export const useSearch = (fieldName: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(searchParams.get(fieldName) || '');

  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setSearchParams({ [fieldName]: state });
    },
    [fieldName, setSearchParams, state],
  );

  const onClear = useCallback(() => {
    searchParams.delete(fieldName);
    searchParams.delete('offset');
    setSearchParams(searchParams);
    setState('');
  }, [fieldName, searchParams, setSearchParams]);

  useEffect(() => {
    setState(searchParams.get(fieldName) || '');
  }, [fieldName, searchParams]);

  return { state, setState, onSubmit, onClear };
};
