import { Address } from 'models/common/Address';

export interface User {
  id: string;
  username: string;
  company_id: string;
  office_id: string;
  department: string;
  shipping_address_same_as_company_address: boolean;
  email: string;
  password: string;
  phone: string;
  first_name: string;
  last_name: string;
  nickname: string;
  reset_password_expires_at: string;
  email_verified: boolean;
  email_verification_token: string;
  role: Roles;
  created_at: string;
  updated_at: string;
  language: number;
  gender: string;
  age: number;
  billing_address: Address;
  shipping_address: Address;
  lon: number;
  lat: number;
  last_activity_date: number;
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  utm_term: string;
  utm_content: string;
  short_id: string;
  dob: number;
  fl: string;
  answeredQuestions: number;
  seenQuestions: number;
  zip: string;
  address: string;
  lastsynced_from_garmin: string;
  not_first_time: boolean;
  lastsynced_from_apple: string;
  reminders: string[];
}

export enum Roles {
  User = 'user',
  CompanyAdmin = 'company_admin',
  SuperAdmin = 'super_admin',
}

export interface UserEdit {
  first_name: string;
  last_name: string;
  email: string;
  department: string;
  office_id: string;
  company_id: string;
  shipping_address: Address;
  shipping_address_same_as_company_address: boolean;
}
