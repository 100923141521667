import { createAsyncThunk } from '@reduxjs/toolkit';
import companiesService from 'services/companiesService';
import { RequestGetCompaniesDTO, ResponseGetCompaniesDTO } from 'models/company/CompanyDTO';

export const getCompanies = createAsyncThunk<
  ResponseGetCompaniesDTO,
  RequestGetCompaniesDTO,
  { rejectValue: string[] }
>('companies/getCompanies', async (params, thunkAPI) => {
  try {
    return await companiesService.getCompanies(params);
  } catch (err: any) {
    const { message } = err.response.data;
    const errMessages = Array.isArray(message) ? message : [message];

    return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
  }
});
