import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { Roles } from 'models/user/User';

const Header = () => {
  const { user } = useAppSelector(state => state.user);
  const isSuperAdmin = user?.role === Roles.SuperAdmin;

  return (
    <TableHead>
      <TableRow>
        {isSuperAdmin && <TableCell>my_air_user_id</TableCell>}
        <TableCell>Employee's Name</TableCell>
        <TableCell align="center">Email Address</TableCell>
        <TableCell align="center">Starting date</TableCell>
        <TableCell align="center">Next order date</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default Header;
