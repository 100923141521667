import { useCallback } from 'react';
import { useAppDispatch } from 'hooks/useRedux';
import { getEmployees } from '../thunks/getEmployees';
import { useParams, useSearchParams } from 'react-router-dom';

export const useEmployees = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { companyId } = useParams();

  return useCallback(() => {
    if (!!companyId) {
      dispatch(
        getEmployees({
          companyId,
          limit: Number(searchParams.get('limit')) || 10,
          offset: Number(searchParams.get('offset')) || 0,
        }),
      );
    }
  }, [companyId, dispatch, searchParams]);
};
