import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { Company } from 'models/company/Company';
import { getCompanies } from './thunks/getCompanies';
import { ResponseGetCompaniesDTO } from '../../models/company/CompanyDTO';
import { deleteCompany } from './thunks/deleteCompany';
import { getCompany } from './thunks/getCompany';
import { addCompany } from './thunks/addCompany';
import { updateCompany } from './thunks/updateCompany';

export interface CompaniesState {
  data: {
    [key: string]: Company;
  };
  loading: boolean;
  errors: string[];
  total: number;
  limit: number;
}

const initialState: CompaniesState = {
  data: {},
  loading: false,
  errors: [],
  total: 0,
  limit: 0,
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompanies.fulfilled.type, (state, action: PayloadAction<ResponseGetCompaniesDTO>) => {
        const data: { [key: string]: Company } = {};
        const { companies, limit, total } = action.payload;

        companies.forEach((value: Company) => (data[value.id] = value));
        state.loading = false;
        state.data = data;
        state.limit = limit;
        state.total = total;
      })
      .addCase(deleteCompany.fulfilled.type, (state, action: PayloadAction<Company>) => {
        const nextData = JSON.parse(JSON.stringify(state.data));

        delete nextData[action.payload.id];
        state.loading = false;
        state.data = nextData;
      })
      .addMatcher(isFulfilled(getCompany, addCompany, updateCompany), (state, action: PayloadAction<Company>) => {
        state.loading = false;
        state.data[action.payload.id] = action.payload;
      })
      .addMatcher(isPending(getCompanies, deleteCompany, getCompany, addCompany, updateCompany), state => {
        state.loading = true;
        state.errors = [];
      })
      .addMatcher(isRejected(getCompanies, deleteCompany, getCompany, addCompany, updateCompany), (state, action) => {
        state.loading = false;
        state.errors = action.payload as string[];
      });
  },
});

export default slice.reducer;
