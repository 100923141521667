import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Search from 'components/Search';
import AddIcon from '@mui/icons-material/Add';
import CompanyDialog from 'containers/CompanyDialog';
import { useAppSelector } from 'hooks/useRedux';
import { Roles } from 'models/user/User';

const Controls = () => {
  const [showingAdd, setShowingAdd] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const isSuperAdmin = user?.role === Roles.SuperAdmin;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Search fieldName={'text'} />

        {isSuperAdmin && (
          <Button variant={'contained'} size={'large'} startIcon={<AddIcon />} onClick={() => setShowingAdd(true)}>
            New company
          </Button>
        )}
      </Box>

      <CompanyDialog isOpen={showingAdd} onClose={() => setShowingAdd(false)} />
    </>
  );
};

export default Controls;
