import { createTheme } from '@mui/material';

const shadow = '0px 3px 6px #00000029';

const defaultTheme = createTheme({});

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      light: '#A8C7A5',
      main: '#307B28',
      dark: '#004e00',
    },
    secondary: {
      light: '#ced4d9',
      main: '#587386',
    },
    text: {
      primary: '#587386',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textAlign: 'center',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: shadow,
        },
        sizeLarge: {
          height: 52,
          borderRadius: 26,
          minWidth: 150,
        },
        sizeMedium: {
          height: 32,
          borderRadius: 20,
          minWidth: 100,
          fontSize: 14,
        },
        sizeSmall: {
          height: 40,
          borderRadius: 20,
          minWidth: 100,
          fontSize: 14,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700,
        },
      },
    },
  },
});

theme.shadows[1] = shadow;

export default theme;
