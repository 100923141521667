import React from 'react';
import { Box } from '@mui/material';
import UploadCsv from './components/UploadCSV';

const Controls = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <UploadCsv />
      </Box>
    </>
  );
};

export default Controls;
