import { Button, CircularProgress, Grid, Stack, TableCell, TableRow } from '@mui/material';
import React, { FC, memo } from 'react';
import { useForm } from './hooks/useForm';
import { useDialog } from './hooks/useDialog';
import TextFieldStyled from 'components/formik/TextFieldStyled';
import SelectStyled from 'components/formik/SelectStyled';
import { useAppSelector } from 'hooks/useRedux';
import { Option } from 'components/formik/SelectStyled/SelectStyled';
import AddressInput from 'components/AddressInput';

const DELIVERY_OPTIONS: Option[] = [
  {
    title: 'Yes',
    value: 'Yes',
  },
  {
    title: 'No',
    value: 'No',
  },
];

const EditForm: FC<Props> = ({ userId, onClose }) => {
  const isCreating = !userId;
  const { loading } = useAppSelector(state => state.employees);
  const { formik, officeOptions, onDeliveryChange, onOfficeChange } = useForm({ userId });
  const { _onClose } = useDialog({ userId, onClose, formik });
  const { values, handleBlur, touched, errors, setFieldValue } = formik;

  if (!officeOptions.length) {
    return null;
  }

  return (
    <TableRow>
      <TableCell colSpan={8}>
        <Grid container spacing={1} component={'form'} onSubmit={formik.handleSubmit} autoComplete={'off'} noValidate>
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <TextFieldStyled
                size={'small'}
                required
                label={'First Name'}
                variant={'outlined'}
                name={'first_name'}
                formik={formik}
              />
              <TextFieldStyled
                size={'small'}
                label={'Last Name'}
                variant={'outlined'}
                required
                name={'last_name'}
                formik={formik}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <TextFieldStyled
                size={'small'}
                label={'Department'}
                variant={'outlined'}
                required
                name={'department'}
                formik={formik}
              />
              <SelectStyled
                size={'small'}
                label={'Office Name'}
                name={'office_id'}
                required
                formik={formik}
                onChange={onOfficeChange}
                options={officeOptions}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              {isCreating && (
                <TextFieldStyled
                  size={'small'}
                  label={'Email'}
                  variant={'outlined'}
                  required
                  name="email"
                  formik={formik}
                />
              )}
              <SelectStyled
                size={'small'}
                label={'Home Delivery'}
                name={'shipping_address_same_as_company_address'}
                value={values.shipping_address_same_as_company_address ? 'No' : 'Yes'}
                onChange={onDeliveryChange}
                formik={formik}
                options={DELIVERY_OPTIONS}
              />
              {!values.shipping_address_same_as_company_address && (
                <AddressInput
                  inputProps={{
                    size: 'small',
                    label: 'Address',
                    fullWidth: true,
                    required: true,
                    variant: 'outlined',
                    onBlur: handleBlur,
                    value: values.shipping_address.address1,
                    name: 'shipping_address.address1',
                    error: !!touched.shipping_address?.address1 && !!errors.shipping_address?.address1,
                    // helperText: !!touched.shipping_address?.address1 ? errors.shipping_address?.address1 : '',
                  }}
                  onChange={nextAddress => setFieldValue('shipping_address', nextAddress)}
                  values={values.shipping_address}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ textAlign: 'right', '& > .MuiButton-root': { ml: 0.5, mb: 0.5 } }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button disabled={!(formik.isValid && formik.dirty)} variant="contained" type="submit" size="small">
                  Save
                </Button>
                <Button variant="outlined" onClick={_onClose} size="small">
                  {!!userId ? 'Cancel' : 'Reset'}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

interface Props {
  onClose?: () => void;
  userId?: string;
}

export default memo(EditForm);
