import { createAsyncThunk } from '@reduxjs/toolkit';
import userService from 'services/userService';
import { RequestUploadCsvDTO, ResponseUploadCsvDTO } from 'models/user/UserDTO';

export const uploadCSV = createAsyncThunk<ResponseUploadCsvDTO, RequestUploadCsvDTO, { rejectValue: string[] }>(
  'employees/uploadCSV',
  async (data, thunkAPI) => {
    try {
      const response = await userService.uploadCSV(data);

      if (response?.error?.length > 0) {
        response.error.length = response.error.length > 3 ? 3 : response.error.length;
        return thunkAPI.rejectWithValue(response.error);
      }

      return response;
    } catch (err: any) {
      const { message } = err.response.data;
      const errMessages = Array.isArray(message) ? message : [message];

      return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
    }
  },
);
