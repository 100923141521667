import React, { FC, memo, useCallback } from 'react';
import { Grid, Stack } from '@mui/material';
import FieldTitle from '../FieldTitle';
import TextFieldStyled from 'components/formik/TextFieldStyled';
import { FormikProps } from 'formik';
import SelectStyled from 'components/formik/SelectStyled/SelectStyled';
import { fillArrayWithNumbersTill } from '../../helpers/fillArrayWithNumbersTill';
import Create from './components/Create';
import Edit from './components/Edit';
import LogoUploader from 'components/LogoUploader';

const OFFICES_AMOUNT = fillArrayWithNumbersTill(5);

const MainForm: FC<Props> = ({ formik, isCreating }) => {
  const { values, setFieldValue } = formik;

  const onLogoChange = useCallback(imgUrl => setFieldValue('logo_url', imgUrl), [setFieldValue]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={2}>
        <LogoUploader onChange={onLogoChange} src={values.logo_url} />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <FieldTitle>Company Info</FieldTitle>
            <Stack spacing={2}>
              <TextFieldStyled required value={values.name} name={'name'} formik={formik} label="Company’s name" />
              <TextFieldStyled value={values.domain_name} name={'domain_name'} label="Domain name" formik={formik} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <FieldTitle>Admin</FieldTitle>
            {isCreating ? <Create formik={formik} /> : <Edit formik={formik} />}
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectStyled
              required
              value={values.number_of_delivery_offices}
              name={'number_of_delivery_offices'}
              label={'Num. of delivery offices'}
              formik={formik}
              options={OFFICES_AMOUNT}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface Props {
  isCreating: boolean;
  formik: FormikProps<any>;
}

export default memo(MainForm);
