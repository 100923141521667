import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { LinearProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';
import EditForm from './components/EditForm';
import Header from './components/Header';
import ListItem from './components/ListItem';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Pagination from 'components/Pagination/Pagination';
import { useErrors } from 'hooks/useErrors';
import { deleteEmployee } from '../../thunks/deleteEmployee';
import { useGetCompany } from '../../../../hooks/useGetCompany';

const List: FC = () => {
  const { data: employees, loading, errors, total, limit } = useAppSelector(state => state.employees);
  const company = useGetCompany();
  const pagesCount = Math.ceil(total / limit);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  useErrors(errors);

  const actions = useMemo(
    () => [
      {
        title: 'Edit Info',
        action: (id: string) => setEditId(id),
      },
    ],
    [],
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev') {
      actions.push({
        title: 'Delete User',
        action: (id: string) => dispatch(deleteEmployee(id)),
      });
    }
  }, [actions, dispatch]);

  return (
    <>
      {loading && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table>
          <Header />
          <TableBody>
            {Object.values(employees)?.map(user => {
              if (editId === user.id) {
                return <EditForm key={user.id} userId={user.id} onClose={() => setEditId(undefined)} />;
              }
              return <ListItem data={user} key={user.id} actions={actions} company={company} />;
            })}
            <EditForm />
          </TableBody>
        </Table>
      </TableContainer>
      {pagesCount > 1 && <Pagination count={pagesCount} limit={limit} />}
    </>
  );
};

export default memo(List);
