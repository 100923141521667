import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useAppSelector } from 'hooks/useRedux';
import { LinearProgress } from '@mui/material';
import ListItem from './components/ListItem';
import Pagination from 'components/Pagination';
import CompanyDialog from 'containers/CompanyDialog';
import Header from './components/Header';
import { useErrors } from 'hooks/useErrors';

const List = () => {
  const { data, loading, errors, total, limit } = useAppSelector(state => state.companies);
  const pagesCount = Math.ceil(total / limit);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  useErrors(errors);

  const actions = [
    {
      title: 'Edit Info',
      action: (id: string) => setEditId(id),
    },
  ];

  return (
    <>
      {loading && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }}>
          <Header />
          <TableBody>
            {Object.values(data)?.map((item, index) => (
              <ListItem data={item} key={index} actions={actions} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {pagesCount > 1 && <Pagination count={pagesCount} limit={limit} />}

      {!!editId && <CompanyDialog isOpen={true} companyId={editId} onClose={() => setEditId(undefined)} />}
    </>
  );
};

export default List;
