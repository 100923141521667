import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import localStorageService from 'services/localStorageService';
import { Roles } from 'models/user/User';
import { useAppSelector } from 'hooks/useRedux';

const ALLOWED_ROLES = [Roles.CompanyAdmin, Roles.SuperAdmin];

const PrivateRoute: React.FC = () => {
  const token = localStorageService.getAccessToken();
  const location = useLocation();
  const { user } = useAppSelector(state => state.user);

  if (!token) {
    localStorageService.setRedirection(location.pathname);

    return <Navigate to={{ pathname: '/login' }} replace />;
  }

  if (user && !ALLOWED_ROLES.includes(user.role)) {
    return <Navigate to={{ pathname: '/not-allowed' }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
