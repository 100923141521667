import React, { memo, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useEmployees } from './hooks/useEmployees';
import List from './components/List';
import { useGetCompany } from '../../hooks/useGetCompany';
import Controls from './components/Controls';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

const Employees = () => {
  const fetchEmployees = useEmployees();
  const company = useGetCompany();
  const navigate = useNavigate();

  const onBackwardClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Typography
          component={'h1'}
          onClick={onBackwardClick}
          sx={{
            fontSize: 26,
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <ArrowBackIosIcon />
          Employees
        </Typography>
        {!!company && <Box component={'img'} src={company.logo_url} sx={{ maxHeight: 40 }} />}
      </Box>
      <Controls />
      <List />
    </>
  );
};

export default memo(Employees);
