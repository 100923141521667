import { useAppDispatch } from 'hooks/useRedux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCompanies } from '../thunks/getCompanies';

export const useCompanies = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(
      getCompanies({
        limit: Number(searchParams.get('limit')) || 10,
        offset: Number(searchParams.get('offset')) || 0,
        text: searchParams.get('text') || undefined,
        sort: searchParams.get('sort') || undefined,
      }),
    );
  }, [dispatch, searchParams]);
};
