import * as yup from 'yup';

const employeeShape = {
  first_name: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  last_name: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  email: yup.string().email('must be valid email').required('this field is required'),
  department: yup.string().required('this field is required'),
  office_id: yup.string().required('this field is required'),
  shipping_address_same_as_company_address: yup.boolean(),
  shipping_address: yup
    .object()
    .shape({
      address1: yup.string(),
    })
    .when('shipping_address_same_as_company_address', {
      is: false,
      then: yup.object().shape({
        address1: yup.string().required('this field is required'),
      }),
    })
    .nullable(),
};

export const employeeSchema = yup.object().shape(employeeShape);
