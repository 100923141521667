import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FormikProps } from 'formik';

const TextFieldStyled: FC<TextFieldProps & Props> = ({ formik, name, ...rest }) => {
  const { handleChange, touched, errors, handleBlur, values } = formik;
  const arrayName = name?.split('.');
  let helperText;

  if (!!arrayName && arrayName.length > 2) {
    // @ts-ignore
    const _errors = errors[arrayName[0]]?.[arrayName[1]]?.[arrayName[2]];
    // @ts-ignore
    const _touched = touched[arrayName[0]]?.[arrayName[1]]?.[arrayName[2]];

    helperText = _errors && _touched ? _errors : null;
  } else {
    helperText = errors[name!] && touched[name!] ? errors[name!] : null;
  }

  return (
    <TextField
      fullWidth
      variant="standard"
      onChange={handleChange}
      error={!!helperText}
      name={name}
      // helperText={helperText}
      onBlur={handleBlur}
      value={values[name!]}
      {...rest}
    />
  );
};

interface Props {
  formik: FormikProps<any>;
}

export default TextFieldStyled;
