import * as yup from 'yup';

const baseShape = {
  name: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  number_of_delivery_offices: yup.number().required('this field is required'),
  embed_calendar_code: yup.string(),
  offices: yup.array().of(
    yup.object().shape({
      office_name: yup
        .string()
        .min(2, 'must be at least 2 characters')
        .max(254, 'cannot be more than 254 characters')
        .required('this field is required'),
      address: yup.object().shape({
        address1: yup.string().required('this field is required'),
      }),
      program: yup
        .object()
        .shape({
          program_start_date: yup.string().required('this field is required'),
        })
        .required(),
    }),
  ),
};

const adminShape = {
  programAdminFullName: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(254, 'cannot be more than 254 characters')
    .required('this field is required'),
  programAdminEmail: yup.string().email('must be valid email').required('this field is required'),
  programAdminPhone: yup.string().min(10, 'must be at least 10 characters').required('this field is required'),
};

export const companyEditSchema = yup.object().shape(baseShape);

export const companyCreateSchema = yup.object().shape({ ...baseShape, ...adminShape });
