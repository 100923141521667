import React from 'react';
import { Container, Typography } from '@mui/material';
import Controls from './components/Controls/Controls';
import List from './components/List';
import { useCompanies } from './hooks/useCompanies';

const Companies = () => {
  useCompanies();

  return (
    <Container maxWidth={false} sx={{ py: 2 }}>
      <Typography component={'h1'} sx={{ fontSize: 26, fontWeight: 700, mb: 4 }}>
        Companies Status
      </Typography>
      <Controls />
      <List />
    </Container>
  );
};

export default Companies;
