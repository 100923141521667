import { createAsyncThunk } from '@reduxjs/toolkit';
import companiesService from 'services/companiesService';
import { Company } from 'models/company/Company';

export const deleteCompany = createAsyncThunk<Company, string, { rejectValue: string[] }>(
  'companies/deleteCompany',
  async (companyId, thunkAPI) => {
    try {
      return await companiesService.deleteCompany(companyId);
    } catch (err: any) {
      const { message } = err.response.data;
      const errMessages = Array.isArray(message) ? message : [message];

      return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
    }
  },
);
