import React, { FC, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Roles, User } from 'models/user/User';
import TableActions from 'components/TableActions';
import { Company, Office } from 'models/company/Company';
import { formatDateString } from 'helpers/formatDateString';
import { useAppSelector } from 'hooks/useRedux';

const ListItem: FC<Props> = ({ data, actions, company }) => {
  const [office, setOffice] = useState<Office | undefined>();
  const { user } = useAppSelector(state => state.user);
  const isSuperAdmin = user?.role === Roles.SuperAdmin;

  useEffect(() => {
    if (!!company) {
      const nextOffice = company.offices.find(office => office.id === data.office_id);
      setOffice(nextOffice);
    }
  }, [company, data.office_id]);

  return (
    <TableRow sx={{ '&:last-child td': { border: 0 } }}>
      {isSuperAdmin && <TableCell>{data.id}</TableCell>}
      <TableCell>{`${data.first_name} ${data.last_name}`}</TableCell>
      <TableCell align="center">{data.email}</TableCell>
      <TableCell align="center">{formatDateString(office?.program?.program_start_date)}</TableCell>
      <TableCell align="center">{formatDateString(office?.program?.next_order_date)}</TableCell>
      <TableCell align="center">
        <TableActions itemId={data.id} actions={actions} />
      </TableCell>
    </TableRow>
  );
};

interface Props {
  company: Company | null;
  data: User;
  actions: { title: string; action: (data: string) => void }[];
}

export default ListItem;
