import React, { FC, memo } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Button, CircularProgress, Container, IconButton, Paper, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Transition from './components/Transition';
import MainForm from './components/MainForm';
import ProgramForm from './components/ProgramForm';
import { useForm } from './hooks/useForm';
import SubmitDialog from 'components/SubmitDialog';
import { useDialog } from './hooks/useDialog';
import { useAppSelector } from 'hooks/useRedux';

const CompanyDialog: FC<Props> = ({ companyId, isOpen, onClose }) => {
  const { loading } = useAppSelector(state => state.companies);
  const { formik } = useForm({ companyId });
  const { _onClose, warning } = useDialog({ formik, onClose });

  return (
    <>
      <Dialog
        PaperProps={{ sx: { backgroundColor: '#fbfbfb' } }}
        fullScreen
        open={isOpen}
        onClose={_onClose}
        TransitionComponent={Transition}
      >
        <Box component={'form'} autoComplete={'off'} onSubmit={formik.handleSubmit} noValidate>
          <Container sx={{ display: 'flex', justifyContent: 'space-between', py: 4, alignItems: 'center' }}>
            <Typography component={'h2'} sx={{ fontSize: 24, fontWeight: 700 }}>
              {`${!companyId ? 'Create New' : 'Edit'} company`}
            </Typography>
            <Stack spacing={2} direction={'row'}>
              <Button disabled={loading} onClick={_onClose} variant={'outlined'} size={'large'}>
                Cancel
              </Button>
              <Button
                disabled={!(formik.isValid && formik.dirty) || loading}
                type={'submit'}
                variant={'contained'}
                size={'large'}
              >
                {loading ? <CircularProgress /> : 'Submit'}
              </Button>
            </Stack>
          </Container>
          <Container>
            <MainForm formik={formik} isCreating={!companyId} />
            {!!formik.values.offices?.length && (
              <Paper sx={{ p: 2, mb: 2 }}>
                {formik.values.offices.map((program, index) => (
                  <ProgramForm key={index} formik={formik} index={index} />
                ))}
              </Paper>
            )}
          </Container>
        </Box>

        <IconButton onClick={_onClose} sx={{ position: 'fixed', top: 4, right: 4 }}>
          <CloseIcon />
        </IconButton>
      </Dialog>

      <SubmitDialog
        isWarning
        title={'Are you sure you want to cancel entered data?'}
        isOpen={warning.isOpen}
        onClose={warning.onCancel}
        onSubmit={warning.onSubmit}
      />
    </>
  );
};

interface Props {
  companyId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export default memo(CompanyDialog);
