import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'models/user/User';
import userService from 'services/userService';

export const addEmployee = createAsyncThunk<User, User, { rejectValue: string[] }>(
  'employees/addEmployee',
  async (user, thunkAPI) => {
    try {
      return await userService.addUser(user);
    } catch (err: any) {
      const { message } = err.response.data;
      const errMessages = Array.isArray(message) ? message : [message];

      return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
    }
  },
);
