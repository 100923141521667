import React, { FC, memo, useCallback } from 'react';
import { Box, TableCell } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SortIcon: FC<{ value: string | null }> = ({ value }) => {
  const fontSize = 20;

  if (!value) {
    return null;
  }

  if (value.charAt(0) === '-') {
    return <ArrowUpwardIcon sx={{ fontSize }} />;
  } else {
    return <ArrowDownwardIcon sx={{ fontSize }} />;
  }
};

const SortByCell: FC<Props & TableCellProps> = ({ sortField, children, ...cellProps }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get('sort');
  const isCurrentSorted = value?.replace('-', '') === sortField || !value;

  const onClick = useCallback(() => {
    if (!!value && isCurrentSorted) {
      if (value.charAt(0) === '-') {
        searchParams.delete('sort');
      } else {
        searchParams.set('sort', `-${sortField}`);
      }
    } else {
      searchParams.delete('offset');
      searchParams.set('sort', sortField);
    }

    setSearchParams(searchParams);
  }, [isCurrentSorted, searchParams, setSearchParams, sortField, value]);

  return (
    <TableCell {...cellProps} onClick={onClick}>
      <Box sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
        {children}
        {isCurrentSorted && <SortIcon value={value} />}
      </Box>
    </TableCell>
  );
};

interface Props {
  sortField: string;
  children: string;
}

export default memo(SortByCell);
