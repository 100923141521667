import React from 'react';
import AppRoutes from 'routes/AppRoutes';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'theme/theme';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';

const App = () => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          {/*@ts-ignore*/}
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <CssBaseline />
              <AppRoutes />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
};

export default App;
