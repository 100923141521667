import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

export const useErrors = (errors: string[]) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!!errors?.length) {
      errors.forEach(error => {
        enqueueSnackbar(error, { variant: 'error' });
      });
    }
  }, [enqueueSnackbar, errors]);
};
