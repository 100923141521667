import React, { FC, memo } from 'react';
import { Box, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as styles from './Search.styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearch } from './hooks/useSearch';

const Search: FC<Props> = ({ fieldName }) => {
  const { state, setState, onSubmit, onClear } = useSearch(fieldName);

  return (
    <Box component={'form'} onSubmit={onSubmit} sx={styles.root} autoComplete={'off'}>
      <SearchIcon sx={{ fontSize: 32, mr: 1 }} />
      <InputBase
        sx={styles.input}
        placeholder={'Search by Company/Domain’s name'}
        value={state}
        onChange={ev => setState(ev.target.value)}
      />
      {!!state && (
        <IconButton
          onClick={onClear}
          sx={{ position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};

interface Props {
  fieldName: string;
}

export default memo(Search);
