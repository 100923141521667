import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import { LinearProgress } from '@mui/material';

const Company = React.lazy(() => import('pages/Company'));
const Companies = React.lazy(() => import('pages/Companies'));
const Layout = React.lazy(() => import('containers/Layout'));
const Login = React.lazy(() => import('pages/Login'));
const NotAllowed = React.lazy(() => import('pages/NotAllowed'));

const AppRoutes = () => (
  <Suspense fallback={<LinearProgress />}>
    <ScrollToTop>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="not-allowed" element={<NotAllowed />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Companies />} />
            <Route path=":companyId/*" element={<Company />} />
          </Route>
        </Route>
      </Routes>
    </ScrollToTop>
  </Suspense>
);

export default AppRoutes;
