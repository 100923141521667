import React, { FC, memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteValue, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from 'hooks/useDebounce';
import userService from 'services/userService';
import { User } from 'models/user/User';
import { TextFieldProps } from '@mui/material/TextField/TextField';

const UserSearch: FC<Props & TextFieldProps> = ({ onSelect, value, ...rest }) => {
  const [searchString, setSearchString] = useState('');
  const [options, setOptions] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchString = useDebounce(searchString, 1000);

  useEffect(() => {
    const getUsersByEmail = async (debouncedSearchString: string) => {
      if (debouncedSearchString?.length > 5) {
        setLoading(true);
        const result = await userService.getUsersByEmail(debouncedSearchString);
        setLoading(false);
        setOptions(result.users);
      }
    };

    getUsersByEmail(debouncedSearchString).then();
  }, [debouncedSearchString]);

  const _onSelect = useCallback(
    (ev: SyntheticEvent, value: AutocompleteValue<any, any, any, any>) => {
      if (!!onSelect) {
        onSelect(value.id);
      }
    },
    [onSelect],
  );

  return (
    <Autocomplete
      disableClearable
      loading={loading}
      onChange={_onSelect}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.email}
      options={options}
      filterOptions={x => x}
      onInputChange={(ev, value) => setSearchString(value)}
      renderInput={params => (
        <TextField
          {...params}
          {...rest}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

interface Props {
  value: string;
  onSelect: (value: User | null) => void;
}

export default memo(UserSearch);
