import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FormikProps } from 'formik';
import { SelectProps } from '@mui/material/Select/Select';

const SelectStyled: FC<SelectProps & Props> = ({ name, required = false, label, formik, options, ...rest }) => {
  const { handleChange, values, touched, errors, handleBlur } = formik;

  return (
    <FormControl required={required} fullWidth size={rest.size} error={!!touched[name!] && !!errors[name!]}>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        value={values[name!] || ''}
        label={label}
        {...rest}
      >
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface Props {
  formik: FormikProps<any>;
  options: Option[];
}

export interface Option {
  title: string;
  value: number | string;
}

export default SelectStyled;
