import { useFormik } from 'formik';
import { address as addressSchema } from '../../../schemas/address';
import { Address } from 'models/common/Address';

export const useForm = (values: Address, onChange: (data: Address) => void, onClose: () => void) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema: addressSchema,
    onSubmit: values => {
      onChange(values);
      onClose();
    },
  });

  return { formik };
};
