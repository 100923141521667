import React, { FC, memo, useState } from 'react';
import { TextField } from '@mui/material';
import { Address } from 'models/common/Address';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import Form from './components/Form';

const AddressInput: FC<Props> = ({ inputProps, values, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TextField {...inputProps} onClick={() => setIsOpen(true)} />

      {isOpen && <Form defaultValues={values} onChange={onChange} onClose={() => setIsOpen(false)}  />}
    </>
  );
};

interface Props {
  inputProps: TextFieldProps;
  values: Address;
  onChange: (data: Address) => void;
}

export default memo(AddressInput);
