export const root = {
  px: 2.5,
  height: 52,
  background: '#fff',
  boxShadow: 1,
  borderRadius: '26px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  position: 'relative',
} as const;

export const input = {
  fontSize: 14,
  '& input': {
    width: 350,
  },
};
