import React, { FC } from 'react';
import { Grid, InputAdornment, Stack } from '@mui/material';
import TextFieldStyled from 'components/formik/TextFieldStyled';
import HtmlIcon from '@mui/icons-material/Html';
import { FormikProps } from 'formik';
import Phone from 'components/formik/Phone';

const Create: FC<Props> = ({ formik }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <TextFieldStyled required name={'programAdminFullName'} label="Admin Full Name" formik={formik} />
          <Phone
            inputProps={{
              required: true,
              name: 'programAdminPhone',
              label: 'Admin Phone Number',
            }}
            formik={formik}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <TextFieldStyled
            required
            name={'programAdminEmail'}
            label="Admin Email Address"
            formik={formik}
            type={'email'}
          />
          <TextFieldStyled
            name={'embed_calendar_code'}
            label="Embedding Calendly"
            formik={formik}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HtmlIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

interface Props {
  formik: FormikProps<any>;
}

export default Create;
