import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useFormik } from 'formik';
import { Address } from 'models/common/Address';
import { User, UserEdit } from 'models/user/User';
import { useParams } from 'react-router-dom';
import { Option } from 'components/formik/SelectStyled/SelectStyled';
import { addEmployee } from '../../../../../thunks/addEmployee';
import { updateEmployee } from '../../../../../thunks/updateEmployee';
import { employeeSchema } from '../schemas/employee';
import { useCallback } from 'react';
import { SelectChangeEvent } from '@mui/material';

export const initialAddress: Address = {
  address1: '',
  address2: '',
  city: '',
  phone: '',
  country: '',
  province: '',
  zip_code: '',
};

const initialUser: UserEdit = {
  office_id: '',
  company_id: '',
  first_name: '',
  last_name: '',
  email: '',
  department: '',
  shipping_address_same_as_company_address: true,
  shipping_address: initialAddress,
};

export const useForm = ({ userId }: { userId?: string }) => {
  const isCreating = !userId;
  const { companyId } = useParams();
  const { data: employees } = useAppSelector(state => state.employees);
  const { data: companies } = useAppSelector(state => state.companies);
  initialUser.company_id = companyId || '';
  const initialValues = isCreating ? initialUser : employees[userId];
  const dispatch = useAppDispatch();
  const officeOptions: Option[] = [{ title: '', value: '' }];

  if (!!companyId && !!Object.keys(companies).length) {
    const company = companies[companyId];

    officeOptions.splice(0, 1);
    company.offices.forEach(office => {
      officeOptions.push({
        title: office.office_name,
        value: office.id,
      });
    });
  }

  const formik = useFormik({
    initialValues,
    validationSchema: employeeSchema,
    onSubmit: async values => {
      if (isCreating) {
        await dispatch(addEmployee(values as User));
      } else {
        await dispatch(updateEmployee(values as User));
      }
    },
  });

  const onDeliveryChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const isHomeDelivery = e.target.value === 'Yes';
      formik.setFieldValue('shipping_address_same_as_company_address', !isHomeDelivery);
      formik.setFieldValue(
        'shipping_address',
        isHomeDelivery ? initialValues.shipping_address || initialAddress : null,
      );
      formik.validateForm().then();
    },
    [formik, initialValues.shipping_address],
  );

  const onOfficeChange = (ev: SelectChangeEvent<unknown>) => {
    if (!companyId) {
      return null;
    }
    const company = companies[companyId];
    const officeId = ev.target.value;

    formik.setFieldValue('office_id', officeId);
    const office = company.offices?.find(office => office.id === officeId);

    if (!!office) {
      formik.setFieldValue('shipping_address_same_as_company_address', !office.program.home_delivery);
    }
  };

  return { formik, officeOptions, onDeliveryChange, onOfficeChange };
};
