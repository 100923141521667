import React, { FC } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FormikProps } from 'formik';

const Phone: FC<Props> = ({ formik, inputProps }) => {
  const { values, errors, touched, handleBlur, setFieldValue } = formik;
  const { name = '' } = inputProps;

  return (
    <MuiPhoneNumber
      fullWidth
      {...inputProps}
      autoFormat={false}
      value={values[name]}
      defaultCountry={'il'}
      onBlur={handleBlur}
      onChange={value => setFieldValue(name, value)}
      error={!!touched[name] && !!errors[name]}
      // helperText={!!touched[name] && (errors[name] || '')}
    />
  );
};

interface Props {
  inputProps: TextFieldProps;
  formik: FormikProps<any>;
}

export default Phone;
