import React, { FC } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import TextFieldStyled from 'components/formik/TextFieldStyled';
import { useForm } from './hooks/useForm';
import { Address } from 'models/common/Address';
import Phone from 'components/formik/Phone';

const Form: FC<Props> = ({ defaultValues, onChange, onClose }) => {
  const { formik } = useForm(defaultValues, onChange, onClose);

  return (
    <Dialog open={true} onClose={onClose} maxWidth={'md'}>
      <DialogTitle>Edit Address</DialogTitle>
      <DialogContent>
        <Box component={'form'} autoComplete={'off'} onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextFieldStyled
                required
                value={formik.values.address1}
                name={'address1'}
                formik={formik}
                label="Address Line 1"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldStyled
                value={formik.values.address2}
                name={'address2'}
                formik={formik}
                label="Address Line 2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldStyled
                required
                value={formik.values.country}
                name={'country'}
                formik={formik}
                label="Country"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldStyled required value={formik.values.city} name={'city'} formik={formik} label="City" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldStyled value={formik.values.province} name={'province'} formik={formik} label="Province" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Phone
                inputProps={{
                  required: true,
                  name: 'phone',
                  label: 'Phone',
                }}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldStyled
                required
                value={formik.values.zip_code}
                name={'zip_code'}
                formik={formik}
                label="ZIP Code"
                type={'number'}
              />
            </Grid>
          </Grid>
          <Stack spacing={2} direction={'row'} sx={{ justifyContent: 'flex-end', mt: 4 }}>
            <Button size={'large'} onClick={onClose} variant={'outlined'}>
              Cancel
            </Button>
            <Button disabled={!(formik.isValid && formik.dirty)} size={'large'} type={'submit'} variant={'contained'}>
              Submit
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface Props {
  defaultValues: Address;
  onChange: (data: Address) => void;
  onClose: () => void;
}

export default Form;
