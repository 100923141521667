import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestGetEmployeesDTO, ResponseGetEmployeesDTO } from 'models/user/UserDTO';
import userService from 'services/userService';

export const getEmployees = createAsyncThunk<
  ResponseGetEmployeesDTO,
  RequestGetEmployeesDTO,
  { rejectValue: string[] }
>('employees/getEmployees', async (params, thunkAPI) => {
  try {
    return await userService.getUsersByCompany(params);
  } catch (err: any) {
    const { message } = err.response.data;
    const errMessages = Array.isArray(message) ? message : [message];

    return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
  }
});
