import { createAsyncThunk } from '@reduxjs/toolkit';
import companiesService from 'services/companiesService';
import { RequestUpdateCompanyDTO } from 'models/company/CompanyDTO';
import { Company } from 'models/company/Company';

export const updateCompany = createAsyncThunk<
  Company,
  { companyId: string; data: RequestUpdateCompanyDTO },
  { rejectValue: string[] }
>('companies/updateCompany', async (data, thunkAPI) => {
  try {
    return await companiesService.updateCompany(data);
  } catch (err: any) {
    const { message } = err.response.data;
    const errMessages = Array.isArray(message) ? message : [message];

    return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
  }
});
