import { useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import { usePrevious } from 'hooks/usePrevious';
import { useAppSelector } from 'hooks/useRedux';

export const useDialog = ({ userId, formik, onClose }: Props) => {
  const { errors } = useAppSelector(state => state.employees);
  const isCreating = !userId;

  const _onClose = useCallback(() => {
    if (isCreating) {
      formik.resetForm();
    } else {
      if (onClose) {
        onClose();
      }
    }
  }, [formik, isCreating, onClose]);

  const prevSubmitting = usePrevious(formik.isSubmitting);

  useEffect(() => {
    if (!formik.isSubmitting && prevSubmitting && !errors.length) {
      _onClose();
    }
  }, [_onClose, errors.length, formik.isSubmitting, prevSubmitting]);

  return { _onClose };
};

interface Props {
  formik: FormikProps<any>;
  onClose?: () => void;
  userId?: string;
}
