import { createAsyncThunk } from '@reduxjs/toolkit';
import userService from 'services/userService';

export const getUserThunk = createAsyncThunk('user/getUserThunk', async () => {
  try {
    return await userService.getUser();
  } catch (e: any) {
    throw e.response.data.message ? e.response.data.message : 'Something went wrong while getting yours details...';
  }
});
