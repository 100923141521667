import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, DialogContentText } from '@mui/material';

const SubmitDialog: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  isWarning = false,
  title = 'Are you sure?',
  children,
}) => {
  const _onSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {!!children && (
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button size={'large'} onClick={onClose} variant={'outlined'} color={'secondary'} autoFocus>
          Cancel
        </Button>
        <Button size={'large'} onClick={_onSubmit} variant={'contained'} color={isWarning ? 'error' : 'success'}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isWarning?: boolean;
  title?: string;
  children?: string;
}

export default SubmitDialog;
