import React, { FC, memo, useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const TableActions: FC<Props> = ({ itemId, actions }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onSelect = useCallback(
    (callback: () => void) => () => {
      callback();
      setAnchorEl(null);
    },
    [],
  );

  return (
    <>
      <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
        <MoreHorizIcon color={'success'} />
      </IconButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((action, index) => (
          <MenuItem key={index} onClick={onSelect(() => action.action(itemId))}>
            {action.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface Props {
  itemId: string;
  actions: { title: string; action: (data: string) => void }[];
}

export default memo(TableActions);
