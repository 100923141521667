import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'models/user/User';
import userService from 'services/userService';

export const deleteEmployee = createAsyncThunk<User, string, { rejectValue: string[] }>(
  'employees/deleteEmployee',
  async (id, thunkAPI) => {
    try {
      return await userService.deleteUser(id);
    } catch (err: any) {
      const { message } = err.response.data;
      const errMessages = Array.isArray(message) ? message : [message];

      return thunkAPI.rejectWithValue(errMessages || ['Bad request']);
    }
  },
);
