import { Address } from 'models/common/Address';

export interface Company {
  id: string;
  admin_user_id: string;
  name: string;
  status: Status;
  domain_name: string;
  logo_url: string;
  employee_num: number;
  offices: Office[];
  embed_calendar_code: string;
  show_dashboard: boolean;
}

export interface Office {
  id: string;
  office_name: string;
  address: Address;
  program: Program;
}

export interface Program {
  number_of_month_for_each_subscription: number;
  watch: boolean;
  webinar: boolean;
  nutrition_expert: boolean;
  stress_expert: boolean;
  program_start_date: string;
  next_order_date: string;
  home_delivery: boolean;
}

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
}
