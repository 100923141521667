import React, { FC, useRef } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useLogoUploader } from './hooks/useLogoUploader';

const LogoUploader: FC<Props> = ({ onChange, src }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { preview, loading, onInputChange, onRootClick } = useLogoUploader({ onChange, src, inputRef });

  return (
    <>
      <Box
        onClick={onRootClick}
        sx={{
          cursor: 'pointer',
          width: '100%',
          paddingTop: '100%',
          background: preview ? null : '#D4DADF',
          borderRadius: '50%',
          position: 'relative',
          overflow: preview ? 'hidden' : 'visible',
        }}
      >
        {loading ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress sx={{ display: 'block' }} />
          </Box>
        ) : !!preview ? (
          <Box
            component={'img'}
            src={preview}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          <>
            <Typography
              component={'span'}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textTransform: 'uppercase',
                fontWeight: 700,
                display: 'block',
              }}
            >
              Logo
            </Typography>
            <AddCircleIcon
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '25%',
                height: '25%',
              }}
              color={'secondary'}
            />
          </>
        )}
      </Box>
      <input type="file" hidden onChange={onInputChange} accept={'image/png, image/jpeg'} ref={inputRef} />
    </>
  );
};

interface Props {
  onChange: (url: string) => void;
  src?: string;
}

export default LogoUploader;
